.renderer-print-version {
  visibility: hidden;
  /*display: block;*/
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  /*max-height: 1000px;*/
  overflow: visible;
  width: 18cm;
}

.print-view {
  padding: 0;
  margin: 0;
  visibility: hidden;
  width: 18cm !important;
  position: absolute;
  top: 0;
  left: 0;
}

.conditions-of-carriage-print-version {
  top: 0;
  visibility: hidden;
}

.conditionsOfCarriage {
  margin: 0;
  padding: 0;
  overflow: visible;
  font-size: small;
  font-family: "serif";
  line-height: 1;
}

.token-history-print-version {
  visibility: hidden;
  margin: 0;
  padding: 0;
  /*display: block;*/
  /*max-height: 1000px;*/
  overflow: visible;
  width: 18cm;
  max-width: 18cm;
}

@media print {
  root,
  body
  {
    width: 18cm !important;
    visibility: hidden !important;
  }

  onboard-v2 {
    display: none;
  }

  .no-print {
    display: none;
  }

  #manage-tt-item {
    display: none !important;
  }

  iframe {
    margin: 0;
    padding: 0;
    min-height: 29cm;
  }

  .pagebreak {
    page-break-before: always;
    page-break-after: always;
    min-height: 3cm;
  }

  .renderer-print-version {
    height: 29cm;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    float: none;
    visibility: visible;
    /*width: 200mm;*/
    /*max-width: 200mm;*/
    break-inside: avoid;
    /*transform: scale(1.5);*/
    /*transform-origin: top left;*/
    -moz-transform: scale(3);
    -moz-transform-origin: top left;
  }

  .token-history-print-version {
    /*top: 85cm !important;*/
    /*position: fixed;*/

    visibility: visible;
    width: 18cm;
    /*max-width: 18cm;*/
    transform: scale(.7);
    transform-origin: top left;
    -moz-transform: scale(1.5);
    -moz-transform-origin: top left;
  }

  .conditions-of-carriage-print-version {
    min-width: 25cm;
    visibility: visible;
    margin: 0;
    transform: scale(1.42);
    transform-origin: top left;
    padding: 0;
  }

  .print-view {
    visibility: visible;
  }
}
