.renderer-print-version {
    visibility: hidden;
    display: block;
    top: 0;
    left: 0;
    /*max-height: 1000px;*/
    /*overflow: visible;*/
    width: 100%;
    max-width: 100%;
    break-after: page;
}


@media print {
    body,
    onboard-v2 {
        visibility: hidden !important;
    }

    .no-print {
        display: none;
    }

    .renderer-print-version {
        display: block;
        top: 0;
        left: 0;
        visibility: visible;
        overflow: hidden;
        width: 200mm;
        max-width: 200mm;
        break-after: page;
        transform: scale(1.5);
        transform-origin: top left;
        -moz-transform: scale(3);
        -moz-transform-origin: top left;
    }
}